import React from 'react'
import { graphql } from 'gatsby'
import { css } from 'linaria'
import Img from 'gatsby-image'

import Layout from 'components/Layout'
import FooterCTA from 'components/Footer/CTA'

import Button from 'components/Button'
import Section from 'components/Section'
import SectionContent from 'components/Section/Content'
import NonStretchedImage from '../../components/NonStretchedImage'
import { HeroHeading, Subheading, Lead } from '../../components/TextElements'

import Hero from '../../elements/Hero'
import TechnologiesList from '../../elements/TechnologiesList'
import SmallTestimonials from '../../elements/Testimonials/Small'
import IconBox from '../../elements/IconBox'
import Products from '../../elements/Products'
import FAQ from '../../elements/FAQ'

import rhythm from '../../utils/rhythm'
import breakpoints from '../../utils/tokens/breakpoints'
import { colors } from '../../utils/theme'

const ogImage = '/img/og/services-og.jpg'

const heroContent = (
  <>
    <Subheading>Services</Subheading>
    <HeroHeading>
      Raise your page loading speed up to 10 times with static site generators
    </HeroHeading>
    <Lead>
      Website performance is what makes or breaks businesses nowadays. We can
      help you build a modern website, or PWA, that will bring you customers and
      and stay on top of your competition.
    </Lead>
    <Button to="/estimate-project/">Let's talk</Button>
  </>
)

export default ({ location, data }) => (
  <Layout
    location={location}
    title="What we do and how we do it at Bejamas"
    description="Utilizing modern web development tools to help your business with performance, security and scalability issues."
    image={ogImage}
    preLinksComponent={<FooterCTA />}
  >
    <Hero
      content={heroContent}
      image={data.hero}
      imageClassName={css`
        ${{
          [`@media (max-width: ${breakpoints.md})`]: {
            marginRight: '0'
          }
        }}
      `}
      className={css`
        ${{
          paddingBottom: rhythm(2),
          [`@media (max-width: ${breakpoints.md})`]: {
            gridTemplateColumns: '100%',
            rowGap: rhythm(2)
          }
        }}
      `}
      particles={
        <>
          <Img
            fixed={data.p24.childImageSharp.fixed}
            className={css`
              ${{
                bottom: '-13%',
                right: '15.25%',
                [`@media (max-width: ${breakpoints.lg})`]: {
                  display: 'none !important'
                }
              }};
            `}
            style={{ position: 'absolute' }}
          />
        </>
      }
    />
    <Section
      variant="lightGray"
      header={
        <>
          <Subheading tag="p">What we do</Subheading>
          <h2>Perfect for SMBs and enterprise</h2>
        </>
      }
    >
      <SectionContent columnGap="2" rowGap="2">
        <Products
          jamstackContent={
            <>Get your website an unfair advantage in SERP rankings.</>
          }
          pwaContent={
            <>
              Build a fast and scalable web app with all the native app’s
              features.
            </>
          }
          productClassName={css`
            ${{ [`&:before`]: { background: colors.background.white } }}
          `}
        />
      </SectionContent>
    </Section>
    <Section
      header={
        <>
          <Subheading>Workflow</Subheading>
          <h2>Get your project a pro service</h2>
        </>
      }
    >
      <SectionContent oddList>
        <IconBox withNumber transparentBackground>
          <Img fixed={data.icon1.childImageSharp.fixed} alt="Swift" />
          <h3>Settling up for the project’s goals</h3>
          <p>
            You want your web product to hit the highest performance scores? Be
            as secure as it possibly can? Or you want to scale it to multiple
            languages in a blink of an eye? We got your back! We’ll get to know
            what’s most important for your success and have it in mind when
            choosing the best tech set.
          </p>
        </IconBox>
        <IconBox withNumber transparentBackground>
          <Img fixed={data.icon2.childImageSharp.fixed} alt="Swift" />
          <h3>
            Picking the right tools <br />
            for the job
          </h3>
          <p>
            Once we understand your project wants and needs it’s time to swim
            through the sea of technologies and choose the best for your
            project. We’ll propose you a couple of options with their possible
            upsides and downsides. We leave the final decision to you, but we’ll
            always be close to help with advice.
          </p>
        </IconBox>
        <IconBox withNumber transparentBackground>
          <Img fixed={data.icon3.childImageSharp.fixed} alt="Swift" />
          <h3>Setting up a work environment</h3>
          <p>
            When you give us the thumbs up, we’ll set up the project management
            environment to keep you in the loop as we work on the project.
            You’ll get an access to our Slack workspace, Asana, Github
            repository, and all other tools used during the development stage.
            You also get to choose how often you’d like to receive project
            updates from us and in what form should they be in.
          </p>
        </IconBox>
        <IconBox withNumber transparentBackground>
          <Img fixed={data.icon4.childImageSharp.fixed} alt="Swift" />
          <h3>Transparent project management</h3>
          <p>
            Transparency is our core value and it mirrors in our practice.
            Throughout the project you’ll be able to access the code repository
            we’re working on and see what we’ve put together in real time.
            You’ll also have access to our time tracking app for your project to
            see how much time each task consumed.
          </p>
        </IconBox>
        <IconBox withNumber transparentBackground>
          <Img fixed={data.icon5.childImageSharp.fixed} alt="Swift" />
          <h3>No need to say goodbye</h3>
          <p>
            Rarely we see our work ending with the deployment. In most cases, we
            stay with our clients for a long time after developing the product.
            We handle their additional feature requests and ongoing web
            architecture maintenance, so they could focus on growing their
            business.
          </p>
        </IconBox>
      </SectionContent>
    </Section>
    <Section
      variant="lightGray"
      header={
        <>
          <h2>Testimonials</h2>
        </>
      }
    >
      <SectionContent
        rowGap="1"
        columnGap={`calc(4vw + ${rhythm(3 / 2)})`}
        maxWidth="44"
        className={css`
          ${{
            marginTop: rhythm(2),
            gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))'
          }};
        `}
      >
        <SmallTestimonials
          elements={[
            {
              content: `Bejamas is highly proactive; they sought feedback early on and have adapted their workflow to our needs.`,
              author: `William Rudenmalm`,
              role: `CTO, Klira`,
              photo: `/img/william-rudenmalm.jpg`
            },
            {
              content: `The successful switch to JAMstack has increased website and data security while also improving site performance and speed.`,
              author: `Yan Schuppli`,
              role: `Founder, Codevelop Technologies GmbH`,
              photo: `/img/yan-schuppli.jpg`
            },
            {
              content: `Bejamas’ responsiveness and ability to turn constructive feedback into implementable, on-time deliverables are hallmarks of their work. Their can-do attitude made them a valuable partner.`,
              author: `Paolo Stolfo`,
              role: `Art Director, KREAM`,
              photo: `/img/paolo-stolfo.jpg`
            }
          ]}
        />
      </SectionContent>
    </Section>
    <Section
      header={
        <>
          <Subheading>FAQ</Subheading>
          <h2>Questions you probably want to ask</h2>
        </>
      }
    >
      <SectionContent columns="1" maxWidth="30">
        <FAQ
          elements={[
            {
              question: `What’s your hourly rate?`,
              answer: `The hourly rate we agree on depends on a couple of factors, like your project’s complexity. If you’d like to know the range of our prices you’ll find all the information you need in our company presentation.`
            },
            {
              question: `How long will my project take?`,
              answer: `A JAMstack website with 5-7 different design templates takes us between 4-6 weeks of development work (with full-CMS option). Of course, assuming that we have all the design assets provided.`
            },
            {
              question: `How long has Bejamas been around?`,
              answer: `Although all of us have been roaming the web and marketing world for a long time, we’re a pretty young brand. Together we started as a small web development shop for SMBs in 2017, and Bejamas (as in a shape you see today) was happily born in 2018. `
            },
            {
              question: `Do you have any past jobs to share?`,
              answer: `With over 30 websites and apps built with static site generators, we do have a bunch of them. Up to browse the full portfolio? Contact our sales team - they’ll be happy to send you a showcase presentation.`
            },
            {
              question: `Do you have your own designers?`,
              answer: `As we’re the dev shop, not a design one, we don’t have any designers in-house. But good news here, we do know some world-class design agencies and freelancers, as we’ve been working with them on our previous projects many times. Hit us up if you need some recommendations! `
            }
          ]}
        />
      </SectionContent>
    </Section>
  </Layout>
)

export const query = graphql`
  query ServicesPageQuery {
    hero: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "hero-services" }
    ) {
      childImageSharp {
        fluid(maxWidth: 560, traceSVG: { color: "#ff5d3e" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    p24: file(relativePath: { regex: "/img/particles/" }, name: { eq: "24" }) {
      childImageSharp {
        fixed(width: 150, traceSVG: { color: "#0a0116" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    seomonitor: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "seomonitor-cs" }
    ) {
      childImageSharp {
        fluid(maxWidth: 560, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    icon1: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "service_icon_01" }
    ) {
      childImageSharp {
        fixed(height: 250, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    icon2: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "service_icon_02" }
    ) {
      childImageSharp {
        fixed(height: 250, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    icon3: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "service_icon_03" }
    ) {
      childImageSharp {
        fixed(height: 250, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    icon4: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "service_icon_04" }
    ) {
      childImageSharp {
        fixed(height: 250, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    icon5: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "service_icon_05" }
    ) {
      childImageSharp {
        fixed(height: 250, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`
